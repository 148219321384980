//
//
// nurminen.dev website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


//
// webpack entry file
//


// Load assets
import '../css/styles.scss'
import 'FRONTEND/images/favicon.ico'

import { createApp }                from 'vue'

import Buefy                        from '@ntohq/buefy-next'

import 'bulma-modal-fx/dist/js/modal-fx.js'

import dayjs                        from 'dayjs'
import AdvancedFormat               from 'dayjs/plugin/advancedFormat.js'
import isSameOrAfter                from 'dayjs/plugin/isSameOrAfter.js'
import isSameOrBefore               from 'dayjs/plugin/isSameOrBefore.js'

import smoothscroll                 from 'smoothscroll-polyfill'
import * as zenscroll               from 'zenscroll'

import VueForceNextTick             from 'vue-force-next-tick'

import router                       from './router.js'

import App                          from '../pages/App.vue'


dayjs.extend(AdvancedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export { dayjs }

smoothscroll.polyfill()

const zenscrollDefaultDuration = 400 // ms
const zenscrollEdgeOffset = 25 // px
zenscroll.setup(zenscrollDefaultDuration, zenscrollEdgeOffset)


//
// Vue setup
//
export const app = createApp(App)


app.use(router)
app.use(Buefy)
app.use(VueForceNextTick)


app.config.globalProperties.$scroll = zenscroll
app.config.globalProperties.$dayjs  = dayjs


app.mount('#app')
