//
//
// nurminen.dev website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { createRouter, createWebHistory } from 'vue-router'

import Home          from '../pages/Home.vue'


const routes = [
    { path: '/', name: 'home', component: Home },
]


const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'is-active',
    routes
})


export default router
